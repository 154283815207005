import { tracking as barkTrackingLogEvent } from "./bark-tracking";

export default () => {
    let state = {
        pidh: null,
        spidh: null,
        pageName: null,
        experimentSessionToken: null,
        esdIds: [],
		getSellerAnalyticsMetadata: (selector) => {},
    }
    /*
    The core of experiment tracking is this array of targets to listen for events on. Any new actions to be tracked can be added to this array
    * structure is
    *lector: jQuery Selector
    * eventName: event(s) to listen to
    * actionValue/action: Will be input to the submitExpAct function and sent to the ESA table
    * */
    const sellerListEventListenerTargets = {
        // all seller row events will extract spidh the same way and use as actionValue
        // and action will always be click
        sellerRowEvents: [
            {
                selector: '.js-request-quote',
                action: 'request-quote',
                spidDataAttribute: 'sellerProfileId',
				getTrackingData: (spid) => state.getSellerAnalyticsMetadata(spid)
            },
            {
                selector: '.js-view-profile-action',
                action: 'profile-link',
                extraData: 'link-name',
                spidDataAttribute: 'sellerProfileId',
                getTrackingData: (spid) => state.getSellerAnalyticsMetadata(spid)
            },
            {
                selector: '.last-message-container',
                action: 'last-message-area'
            },
            {
                selector: '.js-contact-seller',
                action: 'contact',
                spidDataAttribute: 'seller-profile-id'
            }
        ],
        pageEvents: [
            // page events need an explicit action value which can be null to omit it
            {
                selector: '.js-load-more',
                eventName: 'click.slexp',
                action: 'load-more-suggestions'
            }
        ]
    };

    // all seller profile events have a value of spidh
    const sellerProfileEventListenerTargets = [
        {
            selector: '.js-contact-seller',
            eventName: 'click.spexContactSeller',
            action: 'contact-seller-button',
        },
        {
            selector: '.js-message-seller',
            eventName: 'click.spexMessageSeller',
            action: 'email-button',
        },
        {
            selector: '.js-request-quote',
            eventName: 'click.spexReqQuote',
            action: 'request-quote-button',
            getTrackingData: ($button) => state.getSellerAnalyticsMetadata($button)
        }
    ]

    const submitExpAct = (eventName) => {
        logTrackingEvent(eventName);
    };

    const submitExpActWithVal = (eventName, data) => {
        if (state.esdIds.length > 0) {
            state.esdIds.map((esdh) => {
                logTrackingEvent(eventName, {esdh, ...data});
            });
        } else {
            logTrackingEvent(eventName, data);
        }
    };

    const logTrackingEvent = (eventName, data) => {
        const payload = {
            project_id: state.pidh,
            page: window.location.pathname,
            experimentSessionToken: state.experimentSessionToken,
            ...data,
        };
        barkTrackingLogEvent(
            'seller-list-&-profile',
            eventName,
            payload
        )
    }

    const buildEsdList = () => {
        $('input.esd-id').each((i, esdh) => {
            state.esdIds.push($(esdh).val());
        });
        state.experimentSessionToken = window.Bark?.ENV?.bes_token;
    }

    const setPid = (pid) => {
        state.pidh = pid;
    }

    const setSpid = (spid) => {
        state.spidh = spid;
    }

    const setPagename = (pagename) => {
        state.pageName = pagename;
    }

    /**
     *  add an ESD hash ID (or non hashed i guess, im not your boss) to track events for
     * @param esd string
     */
    const addExperiment = (esd) => {
        state.esdIds.push(esd);
    }

    const initialiseForProfile = (pageName = 'seller-profile', getSellerAnalyticsMetadata) => {        
        if(getSellerAnalyticsMetadata){
            state.getSellerAnalyticsMetadata = getSellerAnalyticsMetadata;
        }
        if ($('#pidh-hv').length > 0) {
            setPid($('#pidh-hv').val());
        }
        setSpid($('#spidh-hv').val());
        setPagename(pageName);
        initialise()
        submitPageLoad(state.pageName, state.spidh);
        addSellerProfileEventListeners();
    }

    const initialiseForList = (pidh, getSellerAnalyticsMetadata) => {
        if(getSellerAnalyticsMetadata){
            state.getSellerAnalyticsMetadata = getSellerAnalyticsMetadata;
        }
        setPagename('seller-list');
        setPid(pidh);
        initialise();
        submitPageLoad(state.pageName);
        addSellerListEventListeners();
    }

    const initialise = () => {
        buildEsdList();
    };

    const addSellerProfileEventListeners = () => {
        // assume we have a sellerProfileIdHash already loaded in the page. Project ID is optional
        if (!state.spidh) {
            state.spidh = $('#spidh-hv').val();
        }
        sellerProfileEventListenerTargets.map(({selector, action, eventName, getTrackingData}) => {
            $('.seller-public-profile-page').off(eventName).on(eventName, selector, () => {
                const trackingData = getTrackingData?.($(`${selector}`)) ?? { sellerProfileId: state.spidh };
                submitExpActWithVal(`seller-profile-${action}`, trackingData);
            });
        })
    }

    const addSellerListEventListeners = () => {
        const $sellerList = $('#project-seller-list');
        sellerListEventListenerTargets.sellerRowEvents.map(({
			selector,
			action,
			extraData,
			spidDataAttribute,
			getTrackingData,
}) => {
            $sellerList.off(`click.${selector}`).on(`click.${selector}`, selector, (e) => {
                const $el = $(e.currentTarget);
                const spid = $el.data(spidDataAttribute);
                let actionString = `${state.pageName}-${action}`;
                if (extraData) {
                    let append = $el.data(extraData);
                    actionString = append ? `${actionString}-${append}` : action;
                }
				const trackingData = getTrackingData?.(spid) ?? { sellerProfileId: spid };
                submitExpActWithVal(actionString, trackingData)
            });
        });
        sellerListEventListenerTargets.pageEvents.map(({selector, eventName, action, actionValue}) => {
            $(selector).off(`${eventName}.${selector}`).on(`${eventName}.${selector}`, () => {
                submitExpAct(`${state.pageName}-${action}`)
            });
        });
    }

    const submitPageLoad = (pageName, spidh) => {
        submitExpActWithVal(`${pageName}-view`, {sellerProfileId: spidh});
    }

	const trackExperimentAllocation = (expName, projectId, buyerId, variant) => {
		barkTrackingLogEvent('Experiment', `${expName} - Experiment Allocation`, {
			project_id: projectId,
			buyer_user_id: buyerId,
			variant,
		});
	};

	const trackListView = (isControlList, payload) => {
		barkTrackingLogEvent(
			isControlList ? 'seller-list-&-profile' : 'marketplace-seller-list',
			'list-view',
			payload
		);
	}

    return {
        initialise,
        initialiseForList,
        initialiseForProfile,
        submitExpAct,
        submitExpActWithVal,
        setSpid,
        setPid,
        setPagename,
        addExperiment,
		trackExperimentAllocation,
		trackListView,
    }
};
