import {
	BuyerProjectInteractionTracking,
	getBuyerProjectSeller,
	tracking as barkTrackingLogEvent,
	EntityTracking,
	_t,
} from '@bark/bark-core';
import { PaginationManager } from '../../../../../bark-core/src/components/pagination';
import BuyerApi from '../../../../../buyer-app/src/helpers/buyer-api';

export default async function () {
	/**
	 * Window.Bark global
	 * @type {window.Bark}
	 */
	let Bark = (window.Bark = window.Bark || {});

	const state = {
		trackingEntities: {},
		entityMap: {},
	};

	/**
	 * Window.Bark.sellerProfile
	 * @type {window.Bark.sellerProfile}
	 */
	let profile = (Bark.sellerProfile = Bark.sellerProfile || {});

	/**
	 * A list of pending AJAX requests to use for
	 * @type {object}
	 */
	var pendingAjax = {};

	/**
	 * The section of the page to navigate to
	 * @type {string}
	 */
	let pageSection;

	/**
	 * A flag to say whether a form is dirty or not
	 * @type {boolean}
	 */
	let formDirty;

	/**
	 * Should the review modal be opened on load
	 * @type {boolean}
	 */
	let openReviewsModal;

	/**
	 * If openReviewsModal is <b>True</b>, the star rating to give
	 * @type {int}
	 */
	let reviewsStars;

	let experimentTracking = BuyerProjectInteractionTracking();
	const isPublicProfile = () =>
		!($('.seller-location.seller-location-distance').length > 0);

	const { Handlebars } = window;

	/**
	 * @type {boolean} True to ignore set selected navigation listener
	 */
	let ignoreSetSelectedNav = false;

	const isDebug = () => new URL(window.location.href).searchParams.get('debug') === '1';

	const entityTracking = new EntityTracking({ nameFormat: 'title', debug: isDebug() });
	const variant = 1;

	if (variant !== null) {
		entityTracking.createEntities([
			{
				id: 'experiment',
				data: { variant },
			},
			{
				id: 'buyer',
				data: {
					bes_token: window.Bark?.ENV?.bes_token || null,
					buyer_id: window.bookable?.projectData?.buyer_user_id,
					country_id: window.Bark?.ENV?.ccid,
				},
			},
			{
				id: 'project',
				data: {
					project_id: window.bookable?.projectData?.project_id,
					category_id: window.bookable?.projectData?.category_id,
				},
			},
			{
				id: 'seller_profile',
				data: window.bookable.sellerProfileEntity,
			},
		]);
		entityTracking.createEvents([
			{
				id: 'profile_view',
				name: 'Profile View',
				group: 'Bookable Seller Profile',
				entities: ['experiment', 'buyer', 'project', 'seller_profile'],
			},
			{
				id: 'profile_click',
				name: 'Profile Click',
				group: 'Bookable Seller Profile',
				entities: ['experiment', 'buyer', 'project', 'seller_profile'],
			},
		]);
		entityTracking.fireTracking('profile_view');
	}

	// backwards compatibility
	const fireTracking = (eventName, entities, additionalData, eventGroup) => {
		entityTracking.fireTracking(eventName, additionalData);
	};

	const updateTrackingEntity = (entityName, data, path = [], append = true) => {
		entityTracking.updateEntity(entityName, data);
	};

	const triggerBackToList = () => {
		const projectHash = window.bookable?.projectData.project_id_hashed;
		const sellerListQueryString = window.bookable?.projectData.query_string;
		window.location.href = `/buyers/bark/${projectHash}/${sellerListQueryString}`;
	};

	const handleEndState = () => {
		const buyerId = window.bookable?.projectData?.buyer_user_id;

		if (!buyerId || typeof buyerId === 'undefined') {
			bugsnagClient.notify(
				new Error('Failed to block Buyer: Could not determine the buyer user id'),
			);
		}

		try {
			return BuyerApi().blockBookableSellersForBuyer(
				buyerId,
				() => {
					setTimeout(() => {
						triggerBackToList();
					}, 500);
				},
				(error) => {
					bugsnagClient &&
						bugsnagClient.notify(new Error('Error getting bookable sellers'), {
							metaData: { error: error },
						});
					return { data: [] };
				},
			);
		} catch (e) {
			bugsnagClient.notify(new Error('Error getting bookable sellers'), {
				metaData: { error: e },
			});
			return { data: [] };
		}
	};

	if ($('#__rct-bookable-cta').length && window.BuyerApp) {
		window.BuyerApp.launchModule('bookable-ctas', '__rct-bookable-cta', {
			sellerId: window.bookable.bookableSellerId,
			categoryName: window.bookable.categoryName,
			currency: window.Bark.ENV.currency_code,
			fireTracking: fireTracking,
			entityTracking: entityTracking,
			updateTrackingEntity: updateTrackingEntity,
			endState: handleEndState,
			showGuarantee: window.bookable.showGuarantee,
		});
	}

	if ($('#__job-section').length && window.BuyerApp) {
		window.BuyerApp.launchModule('seller-jobs', '__job-section', {
			sellerId: window.bookable.bookableSellerId,
			categoryName: window.bookable.categoryName,
			currency: window.Bark.ENV.currency_code,
			fireTracking: fireTracking,
			entityTracking: entityTracking,
			updateTrackingEntity: updateTrackingEntity,
			endState: handleEndState,
			showGuarantee: window.bookable.showGuarantee,
		});
	}

	/**
	 * Scroll to a named section
	 * @param {string} sectionName
	 */
	function scrollToSection(sectionName) {
		var top = ($('.js-section-' + sectionName).offset() || {}).top || 0,
			isDesktopLayout = $(window).width() >= 1024,
			offset = isDesktopLayout ? 140 : 140; // larger offset for fixed mobile nav

		ignoreSetSelectedNav = true;

		highlightNavSection(sectionName);

		$('body,html')
			.finish()
			.animate({ scrollTop: Math.max(top - offset, 0) }, 400, 'linear', () => {
				ignoreSetSelectedNav = false;
			});
	}

	Handlebars.registerHelper('breaklines', function (text) {
		text = Handlebars.Utils.escapeExpression(text).replace(/(\r\n|\n|\r)/gm, '<br>');
		return new Handlebars.SafeString(text);
	});

	// Scroll images and photos horizontally
	// Useful for people without touch, trackpads, or horizontally scrolling mouse
	var isDesktopLayout = $(window).width() >= 1024;
	var scrollOffset = isDesktopLayout ? 585 : 370; // larger offset for desktops
	$('.js-image-scroll-forwards').click(function () {
		entityTracking.fireTracking('profile_click', { cta_name: 'images_next' });
		$('#image-container').animate({ scrollLeft: '+=' + scrollOffset }, 400);
	});
	$('.js-image-scroll-back').click(function () {
		$('#image-container').animate({ scrollLeft: '-=' + scrollOffset }, 400);
		entityTracking.fireTracking('profile_click', { cta_name: 'images_back' });
	});
	$('.js-video-scroll-forwards').click(function () {
		$('#video-container').animate({ scrollLeft: '+=' + scrollOffset }, 400);
	});
	$('.js-video-scroll-back').click(function () {
		$('#video-container').animate({ scrollLeft: '-=' + scrollOffset }, 400);
	});

	//Hide more than three categories behind a disclosure
	$('.js-category-showmore-btn').click(function () {
		$('.badge-pill-initially-hidden').removeClass('badge-pill-initially-hidden'); // Not using .show() because it breaks the layout by resetting to d-block
		$(this).hide();
	});

	/**
	 * Sets the selected navigation item based on the scroll position of the page. Only applies to mobile.
	 */
	function setSelectedNav() {
		if (ignoreSetSelectedNav) {
			// Ignore while scrolling.
			return;
		}

		// Starting from the last section, find the first section with anything visible
		var firstVisibleSection = null;
		$($('.profile-section').get().reverse()).each(function () {
			if ($(this).offset().top + $(this).outerHeight() - $(window).scrollTop() > 78) {
				firstVisibleSection = $(this);
			}
		});
		if (!firstVisibleSection) {
			return;
		}
		// Update which nav item is selected
		highlightNavSection(firstVisibleSection.data('section'));
	}

	let highlightNavSection = (sectionName) => {
		$(
			'.js-profile-nav-container .active-link:not(.js-nav-link' + sectionName + ')',
		).removeClass('active-link');
		$('.js-nav-link-' + sectionName).addClass('active-link');
		$('#priority-nav .dropdown-menu a').removeClass('active-link');
		$('#priority-nav .dropdown-menu a[data-section=' + sectionName + ']').addClass(
			'active-link',
		);
		$('#priority-nav .nav-item.more')[
			$('#priority-nav .dropdown-menu a.active-link').length > 0
				? 'addClass'
				: 'removeClass'
		]('active-link');
		if (window.innerWidth <= 991) {
			// Auto scroll nav but ONLY on devices with mobile nav, otherwise bad things happen :)
			document.getElementById('parent-' + sectionName).scrollIntoView();
		}
	};

	const accreditations = () => {
		let template = Handlebars.compile($('#accreditation-item-template')[0].innerHTML);
		let spidh = $('#spidh-hv').val();

		Bark.api(`sellerprofile/${spidh}/accreditation`, null, ({ status, data }) => {
			data.forEach((item) => {
				$('.js-accreditation-container').append(template(item));
			});
		});
	};

	const showEditProfileLinks = () => {
		if ($('#hb-edit-profile-link').length) {
			const profileUrl = '/settings/profile/my_profile/?&ps=';
			const linkTemplate = Handlebars.compile(
				document.getElementById('hb-edit-profile-link').innerHTML,
			);
			$('[data-edit-profile-link]').each(function () {
				let $el = $(this);
				let addClasses = '';
				if ($el.data('editProfileLinkClass')) {
					addClasses = $el.data('editProfileLinkClass').replace(',', ' ');
				}
				let linkHtml = linkTemplate({
					section: profileUrl + $el.data('editProfileLink'),
					addClasses: addClasses,
				});
				$el.append(linkHtml);
			});
		}
	};

	const requestReplyRqSuccess = () => {
		let reqQuoteButton = $('.js-public-profile-main-info-sect').find('.js-request-quote');

		const requestReplyButton = $('.seller-public-profile-page').find('.js-request-quote');

		if (reqQuoteButton.length) {
			reqQuoteButton.prop('disabled', true);
			reqQuoteButton.text(
				_t('public_seller-profile:main-info-section.reply-requested-button'),
			);
		}

		if (requestReplyButton.length) {
			requestReplyButton.prop('disabled', true);
			requestReplyButton.text(
				_t('public_seller-profile:main-info-section.reply-requested-button'),
			);
		}
	};

	$(function () {
		// BarkVetted tooltip
		$('.bark-vetted-info').tooltip({
			title: _t('seller_profile:tooltip.bark-vetted-badge'),
			template:
				'<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-xs" style="max-width: 240px;"></div></div>',
			trigger: 'hover focus',
		});

		$('.js-profile-load-content')
			.off('click.getcontent')
			.on('click.getcontent', function (f) {
				var t = $(this);
				var data = t.data();
				var payload = {
					suidh: data.suidh,
					spidh: data.spidh,
					thing: data.thing,
				};
				var elem;
				var preventDefault = this.href.substring(this.href.length - 1) === '#';

				if (preventDefault) {
					f.preventDefault();
				}

				if (pendingAjax[payload.thing]) {
					return;
				}

				pendingAjax[payload.thing] = true;

				$.ajax({
					url: Bark.sprintf(
						'%s:%s/tpi',
						window.Bark.ENV.api_hostname,
						window.Bark.ENV.api_port,
					),
					beforeSend: function (xhr) {
						xhr.setRequestHeader('Accept', 'application/vnd.bark.v2+json');
					},
					type: 'post',
					data: payload,
				})
					.done(function (e) {
						if (e.status) {
							if (e.data) {
								// Set the content and then remove the click event
								elem = $('.js-profile-view-link-' + payload.thing);
								elem.text(e.data.label).off('click.getcontent');
								elem.attr({ href: e.data.href });
								elem.addClass('dark-blue');
							}
							return;
						}

						pendingAjax[payload.thing] = false;
						Bark.alertModal(_t('public_seller-profile:generic-error-message'));
					})
					.fail(function () {
						pendingAjax[payload.thing] = false;
						Bark.alertModal(_t('public_seller-profile:generic-error-message'));
					});

				// Record a link to the phone number, and send an email
				if (data.thing === 'tel') {
					Bark.api(
						'seller/contact',
						{
							type: 'phone',
							seller_profile_id: $(this).data('spidh'),
						},
						function () {
							// Do nothing
						},
						function (e) {
							bugsnagClient.notify(e);
						},
						'POST',
						Bark.apiVersionHeader('v2'),
					);
				}

				if (preventDefault) {
					return false;
				}
			});

		$('.js-media-see-all')
			.off('click.opengal')
			.on('click.opengal', function () {
				var t = $(this);
				var scope = t.closest('.js-section-container');

				$('.light-gallery a[href]:first', scope).click();
			});

		$('.js-show-more-company-desc')
			.off('click.showmore')
			.on('click.showmore', function () {
				$('.js-company-desc-more').fadeIn(200);
				$('.js-section-about .no-line-break').removeClass('no-line-break');
				//Remove edit profile link from first paragraph
				$('#edit-profile-link-desc').remove();
				$(this).remove();
			});

		const $lg = $('.light-gallery');
		if ($lg.length) {
			$lg.lightGallery({ getCaptionFromTitleOrAlt: false });
			$lg.on('onBeforeOpen.lg', function () {
				$lg.find('img:not([src])').each(function (i, el) {
					$(el).attr('src', $(el).data('src'));
					$(el).attr('srcset', $(el).data('srcset'));
				});
			});
		}

		$('.js-profile-navigation-link a')
			.off('click.navigate')
			.on('click.navigate', function (e) {
				var page = $(this).data('section');
				e.preventDefault();
				entityTracking.fireTracking('profile_click', {
					cta_name: 'tab_navigation',
					section: page,
				});
				$(window).off('scroll resize');
				scrollToSection(page);
				$('.dropdown-menu').removeClass('show');
				if ($(this).parents('.dropdown-menu').length) {
					$(this).addClass('active-link');
					$('.dropdown').addClass('active-link');
				} else {
					$('.dropdown').removeClass('active-link');
				}
				// Yep, I went there. Will refactor to await the completion in setSelected
				setTimeout(() => {
					$(window).on('scroll resize', setSelectedNav);
				}, 1000);
				return false;
			});

		pageSection = Bark.GET('s');

		if (pageSection) {
			scrollToSection(pageSection);
		}

		openReviewsModal = Bark.GET('show_reviews');
		reviewsStars = Bark.GET('star');

		if (openReviewsModal && reviewsStars) {
			$('#add-review-modal ._st' + reviewsStars).click();
			$('#add-review-modal').modal('show');
		} else if (openReviewsModal) {
			$('#add-review-modal').modal('show');
		}

		if (Bark.GET('amp_clktel')) {
			$('.js-profile-view-link-tel').click();
		}

		if (Bark.GET('show-reviews')) {
			$('.js-nav-link-reviews  a').click();
		}

		var def = $.Deferred();
		window.priorityNavManager.init(null, def);

		if ($('#twitter-container').length) {
			// Load the Twitter library here, prior to calling the `.read()` function, to ensure the library is available
			window.twttr = (function (d, s, id) {
				var js,
					fjs = d.getElementsByTagName(s)[0],
					t = window.twttr || {};
				if (d.getElementById(id)) return;
				js = d.createElement(s);
				js.id = id;
				js.src = 'https://platform.twitter.com/widgets.js';
				fjs.parentNode.insertBefore(js, fjs);

				t._e = [];
				t.ready = function (f) {
					t._e.push(f);
				};

				return t;
			})(document, 'script', 'twitter-wjs');
		}
		window.QS.deleteValue('_loc');

		function fixLayoutInProjectContext() {
			// When logged in as a buyer in project context, there is another nav bar :"Back to quotes".
			// This function deals with the extra height of that nav.

			let backToQuotesSelector = '.seller-profile-back-to-quotes-container';
			let isDesktopLayout = $(window).width() >= 1024;

			// Desktop fixes. Mobile replaces the 'Bark' nav wih the 'back to quotes' one
			if ($(backToQuotesSelector).length && isDesktopLayout) {
				$('.section-navigation').css({ top: '125px' });
				$('.seller-profile-section-container').css({ marginBottom: '125px' });
			}

			// Mobile fixes
			if ($(backToQuotesSelector).length && !isDesktopLayout) {
				// Adjust padding above profile image
				// Using attr because .css doesn't understand !important
				$('.seller-public-profile-main-info-sect').attr(
					'style',
					'padding-top: 10px!important',
				);
			}
		}

		function changeStateOfCarouselScrollButtonsBasedOnScrollPosition() {
			let carouselTypes = ['video', 'image'];
			carouselTypes.forEach(function (carouselType) {
				let carouselSelector = $('#' + carouselType + '-container');
				let forwardButtonSelector = $('.js-' + carouselType + '-scroll-forwards');
				let backButtonSelector = $('.js-' + carouselType + '-scroll-back');

				// Back scroll button state
				if (carouselSelector.prop('scrollLeft') > 0) {
					// Enable the 'scroll right' button
					backButtonSelector.removeClass('disabled');
				} else {
					backButtonSelector.addClass('disabled');
				}

				// Forward scroll button state
				if (
					carouselSelector.prop('scrollLeft') + carouselSelector.prop('clientWidth') <
					carouselSelector.prop('scrollWidth')
				) {
					forwardButtonSelector.removeClass('disabled');
				} else {
					// User has scrolled all the way forwards, to the end of the container, so disable scroll forward button
					forwardButtonSelector.addClass('disabled');
				}
			});
		}

		function tenderBrowseExperimentHandler() {
			let browseCookie = window.browseFunctions.getBlid();
			browseFunctions.tracking('Browse-list - profile view', {
				sellerProfileId: $('#spidh-hv').val(),
			});
			return browseCookie && $('.js-request-quote-tenderbrowse-invoke').length > 0;
		}

		$('#image-container').on(
			'scroll resize',
			changeStateOfCarouselScrollButtonsBasedOnScrollPosition,
		);
		$('#video-container').on(
			'scroll resize',
			changeStateOfCarouselScrollButtonsBasedOnScrollPosition,
		);

		$(window).on('scroll resize', setSelectedNav);

		fixLayoutInProjectContext();
		setSelectedNav();
		if (!tenderBrowseExperimentHandler()) {
			experimentTracking.initialiseForProfile();
		}

		if ($('.js-overview-section:visible').length > 0) {
			$('.js-overview-header').show();
		}
		if ($('#accreditation-item-template').length) {
			$('.js-section-accreditation').show();
			accreditations();
		}
		changeStateOfCarouselScrollButtonsBasedOnScrollPosition();

		showEditProfileLinks();

		if (Bark.GET('show-reviews')) {
			// not ideal, but till we refactor this and use promises.
			setTimeout(() => {
				$('.js-nav-link-reviews  a').click();
			}, 1000);
		}

		if (!isPublicProfile()) {
			let urlPath = location.href.split('/');
			let sellerId = urlPath[urlPath.length - 2];
			getBuyerProjectSeller(
				QS.getValue('prid'),
				sellerId,
				(result) => {
					let locationDistance = result.data?.attributes?.location_distance;
					if (locationDistance && locationDistance.trim().length > 0) {
						$('.seller-location.seller-location-distance .val').text(locationDistance);
						$('.seller-location.seller-location-distance').removeClass('d-none');
					}
				},
				(error) => {
					bugsnagClient.notify(new Error('Failed to get project seller data'), {
						metaData: error,
					});
				},
			);
		}

		let data = window.bookable?.reviews;
		const filterReviews = (page) => {
			const pageSize = 5;
			// collect active filters
			let scores = '';
			$('.js-review-filter:checked').each(function () {
				if (scores) scores += ',';
				scores += $(this).val();
			});
			if (!scores) {
				scores = '1,2,3,4,5';
			}
			let reviews = data.filter((review) => scores.includes(review.score.toString()));
			$('.reviews-parent').addClass('waiting');
			let reviewsContainer = $('.review-collection-container');
			let reviewItemTemplate = Handlebars.compile(
				document.getElementById('review-item-template').innerHTML,
			);
			let currentPage = page;
			let lastPage = reviews.length / pageSize;
			reviews = reviews.slice((currentPage - 1) * pageSize, currentPage * pageSize);
			let newReviews = [];
			for (let j = 0; j < reviews.length; j++) {
				let review = reviews[j];
				if (typeof review.response === 'undefined') {
					review.response = false;
				}
				newReviews.push(
					reviewItemTemplate({
						name: review.name,
						score: review.score * 2.0,
						score_round: Math.round(review.score),
						project_category: '',
						created_date: formatReviewDate(review.created),
						text_main: filterReviewText(review.content, true),
						text_more: filterReviewText(review.content, false),
						source_name: review.source_name,
						response: review.seller_response,
					}),
				);
			}
			reviewsContainer.parent().removeClass('waiting');
			reviewsContainer
				.empty()
				.append(newReviews.join(' '))
				.append($('<div>').prop('id', 'review-collection-pagination').addClass('mt-4'));
			addMoreTextListener();
			new PaginationManager().output(
				reviewsContainer.find('#review-collection-pagination'),
				lastPage,
				currentPage,
				function (page) {
					entityTracking.fireTracking('profile_click', {
						cta_name: 'review_pagination',
						page,
					});
					if (!reviewsContainer.parent().hasClass('waiting')) {
						reviewsContainer.parent().addClass('waiting');
						filterReviews(page);
					}
				},
			);
		};

		const filterReviewText = (reviewText, firstSection) => {
			if (!reviewText) return '';
			if (reviewText.length < 120) return firstSection ? reviewText : '';
			let regex = /((?:.|\s){120}[^\s\.\?\!\-\,\)\]\;\:\n]*)((?:.|[\n\s])*)/;
			let parts = reviewText.match(regex);
			if (parts) {
				return parts[firstSection ? 1 : 2];
			} else {
				return firstSection ? reviewText : '';
			}
		};

		const formatReviewDate = (reviewDate) => {
			return reviewDate
				? new Date(reviewDate.split(' ')[0]).toLocaleDateString(
						'en-' + Bark.LOCALE.iso2c,
						{
							year: 'numeric',
							month: 'long',
							day: 'numeric',
						},
				  )
				: '';
		};

		const addMoreTextListener = () => {
			$('.js-show-more-review-text')
				.off('click.showmore')
				.on('click.showmore', function () {
					var t = $(this);
					entityTracking.fireTracking('profile_click', {
						cta_name: 'review_read_more',
					});
					var scope = t.closest('.js-review-text-container');

					$('.js-more-review-text', scope).removeClass('d-none');
					scope.removeClass('no-line-break');
					t.remove();
				});
		};

		if ($('.js-review-filter').length > 0) {
			$('.js-review-filter')
				.off('change.filter')
				.on('change.filter', function () {
					entityTracking.fireTracking('profile_click', {
						cta_name: 'filter_reviews',
					});
					filterReviews(1);
				});
		}
		filterReviews(1);
	});
}
