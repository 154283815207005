import {
	BuyerProjectInteractionTracking,
	postResponseAction,
	initRequestQuoteFromSellerJs,
	getBuyerProjectSeller,
	tracking as barkTrackingLogEvent,
	_t,
} from '@bark/bark-core';
import { handleEnquiry } from '../../../helpers/enquiries';
import { handleBookNow } from '../../../../../buyer-app/src/helpers/book-now';

export default async function () {
	/**
	 * Window.Bark global
	 * @type {window.Bark}
	 */
	let Bark = (window.Bark = window.Bark || {});

	/**
	 * Window.Bark.sellerProfile
	 * @type {window.Bark.sellerProfile}
	 */
	let profile = (Bark.sellerProfile = Bark.sellerProfile || {});

	/**
	 * A list of pending AJAX requests to use for
	 * @type {object}
	 */
	var pendingAjax = {};

	/**
	 * The section of the page to navigate to
	 * @type {string}
	 */
	let pageSection;

	/**
	 * A flag to say whether a form is dirty or not
	 * @type {boolean}
	 */
	let formDirty;

	/**
	 * Should the review modal be opened on load
	 * @type {boolean}
	 */
	let openReviewsModal;

	/**
	 * If openReviewsModal is <b>True</b>, the star rating to give
	 * @type {int}
	 */
	let reviewsStars;

	let experimentTracking = BuyerProjectInteractionTracking();
	const isPublicProfile = () =>
		!($('.seller-location.seller-location-distance').length > 0);

	const { Handlebars } = window;

	//prices
	function loadInPrices() {
		if ($('.js-section-prices').length < 1) {
			return;
		}
		Handlebars.registerHelper('breaklines', function (text) {
			text = Handlebars.Utils.escapeExpression(text);
			text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
			return new Handlebars.SafeString(text);
		});
		// API call to prices endpoint
		Bark.api(
			'sellerprofile/' + $('#spidh-hv').val() + '/prices',
			{},
			function (data) {
				// on success:
				const priceItemTemplate = Handlebars.compile(
					document.getElementById('hb-price-item').innerHTML,
				);
				if (data.data.length == 0) {
					// this is an error state
					$('.js-section-prices').hide();
					$('.js-nav-link-prices').hide();
				} else {
					$('.prices-list').empty();
					for (let i = 0; i < data.data.length; i++) {
						var price = data.data[i];
						if (price.base_price) {
							$('.prices-list').append(
								priceItemTemplate({
									category_id: price.category_id,
									category_name: price.category_name,
									price_formatted: price.base_price
										? format_currency(price.base_price, true, price.currency_symbol)
										: '',
									currency_symbol: price.currency_symbol ?? Bark.LOCALE.currency_symbol,
									price_unit: price.price_unit === 'onceoff' ? '' : price.price_unit,
									has_discount: price.discounts && true,
									discount: price.discounts,
									has_addon: price.addons && true,
									addon: price.addons,
									price_value_raw: price.base_price,
									index: i,
									firstElement: i === 0,
									has_discount_or_addons:
										(price.discounts && true) || (price.addons && true),
									has_discount_and_addons: price.discounts && price.addons,
								}),
							);
						}
					}
				}
			},
			function (error) {
				$('.js-section-prices').hide();
				$('.js-nav-link-prices').hide();
				bugsnagClient.notify(error);
			},
			'GET',
			Bark.apiVersionHeader('v1'),
		);
	}

	//Company Info: age, employees
	function loadInCompanyInfo() {
		// API call to prices endpoint
		Bark.api(
			'sellerprofile/' + $('#spidh-hv').val() + '/companyinfo',
			{},
			function (data) {
				// on success:
				if (data.data.length == 0) {
					// No company data to show
				} else {
					if (data.data.company_age || data.data.company_age === 0) {
						$('.js-data-years-in-business').text(
							_t(
								'public_seller-profile:company-info-section.years-in-business.in-business-years',
								{
									count: data.data.company_age ?? 0,
								},
							),
						);
						$('.js-overview-header').show();
						$('.js-section-years-in-business').show(100);
					}
					if (data.data.company_size) {
						// Show company size data
						let companySizeToStringMap = {
							'self-employed': _t(
								'public_seller-profile:company-info-section.company-size.self-employed',
							),
							'2-10': _t('public_seller-profile:company-info-section.company-size.2-10'),
							'11-50': _t(
								'public_seller-profile:company-info-section.company-size.11-50',
							),
							'50-200': _t(
								'public_seller-profile:company-info-section.company-size.50-200',
							),
							'over-200': _t(
								'public_seller-profile:company-info-section.company-size.over-200',
							),
						};
						$('.js-overview-header').show();
						$('.js-data-company-size').text(
							companySizeToStringMap[data.data.company_size] ??
								_t('public_seller-profile:company-info-section.company-size.default'),
						);
						$('.js-section-company-size').show(100);
					}
				}
			},
			function (error) {
				$('.js-section-years-in-business').hide();
				$('.js-section-company-size').hide();

				bugsnagClient.notify(new Error(`Could not retrieve company info from api`));
			},
			'GET',
			Bark.apiVersionHeader('v1'),
		);
	}

	// Check if services can be provided remotely
	function loadInRemoteServiceAvailability() {
		// API call to prices endpoint
		Bark.api(
			'sellerprofile/' + $('#spidh-hv').val() + '/services-available-remotely',
			{},
			function (data) {
				// on success:
				if (data.data.length == 0) {
					// No info. Carry on.
				} else {
					if (data.data.available_remotely) {
						// Yep.
						$('.js-overview-header').show();
						$('.js-section-remote-services').show(100);
					}
				}
			},
			function (error) {
				$('.js-section-remote-services').hide();
				bugsnagClient.notify(error);
			},
			'GET',
			Bark.apiVersionHeader('v1'),
		);
	}

	function format_currency(number_pence, abbreviate, currencySymbol) {
		var priceVal = number_pence / 100.0;
		if (abbreviate) {
			if (priceVal >= 1000000) {
				priceVal = number_format(priceVal / 1000000, 1) + 'M';
			} else if (priceVal >= 1000) {
				priceVal = number_format(priceVal / 1000, 1) + 'K';
			} else if (priceVal >= 100) {
				priceVal = number_format(priceVal, 0);
			} else {
				if (priceVal % 1 == 0) {
					// Whole number, don't show decimal places
					priceVal = priceVal ? number_format(priceVal, 0) : 0;
				} else {
					// Finally, format any other number to 2 dp
					priceVal = priceVal ? number_format(priceVal, 2) : 0;
				}
			}
		} else {
			priceVal = number_format(priceVal, 2);
		}
		return '' + currencySymbol + priceVal;
	}

	function number_format(number, decimals, decPoint, thousandsSep) {
		decimals = Math.abs(decimals) || 0;
		number = parseFloat(number);

		if (isNaN(number)) {
			return NaN;
		}

		if (!decPoint || !thousandsSep) {
			decPoint = '.';
			thousandsSep = ',';
		}

		var roundedNumber = Math.round(Math.abs(number) * ('1e' + decimals)) + '';
		var numbersString = decimals
			? roundedNumber.slice(0, decimals * -1) || 0
			: roundedNumber;
		var decimalsString = decimals ? roundedNumber.slice(decimals * -1) : '';
		var formattedNumber = '';

		while (numbersString.length > 3) {
			formattedNumber += thousandsSep + numbersString.slice(-3);
			numbersString = numbersString.slice(0, -3);
		}

		if (decimals && decimalsString.length === 1) {
			while (decimalsString.length < decimals) {
				decimalsString = decimalsString + decimalsString;
			}
		}

		return (
			(number < 0 ? '-' : '') +
			numbersString +
			formattedNumber +
			(decimalsString ? decPoint + decimalsString : '')
		);
	}

	/**
	 * @type {boolean} True to ignore set selected navigation listener
	 */
	let ignoreSetSelectedNav = false;

	/**
	 * Scroll to a named section
	 * @param {string} sectionName
	 */
	function scrollToSection(sectionName) {
		var top = ($('.js-section-' + sectionName).offset() || {}).top || 0,
			isDesktopLayout = $(window).width() >= 1024,
			offset = isDesktopLayout ? 140 : 140; // larger offset for fixed mobile nav

		ignoreSetSelectedNav = true;

		highlightNavSection(sectionName);

		$('body,html')
			.finish()
			.animate({ scrollTop: Math.max(top - offset, 0) }, 400, 'linear', () => {
				ignoreSetSelectedNav = false;
			});
	}

	Handlebars.registerHelper('breaklines', function (text) {
		text = Handlebars.Utils.escapeExpression(text).replace(/(\r\n|\n|\r)/gm, '<br>');
		return new Handlebars.SafeString(text);
	});

	// Scroll images and photos horizontally
	// Useful for people without touch, trackpads, or horizontally scrolling mouse
	var isDesktopLayout = $(window).width() >= 1024;
	var scrollOffset = isDesktopLayout ? 585 : 370; // larger offset for desktops
	$('.js-image-scroll-forwards').click(function () {
		$('#image-container').animate({ scrollLeft: '+=' + scrollOffset }, 400);
	});
	$('.js-image-scroll-back').click(function () {
		$('#image-container').animate({ scrollLeft: '-=' + scrollOffset }, 400);
	});
	$('.js-video-scroll-forwards').click(function () {
		$('#video-container').animate({ scrollLeft: '+=' + scrollOffset }, 400);
	});
	$('.js-video-scroll-back').click(function () {
		$('#video-container').animate({ scrollLeft: '-=' + scrollOffset }, 400);
	});

	//Hide more than three categories behind a disclosure
	$('.js-category-showmore-btn').click(function () {
		$('.badge-pill-initially-hidden').removeClass('badge-pill-initially-hidden'); // Not using .show() because it breaks the layout by resetting to d-block
		$(this).hide();
	});

	/**
	 * Sets the selected navigation item based on the scroll position of the page. Only applies to mobile.
	 */
	function setSelectedNav() {
		if (ignoreSetSelectedNav) {
			// Ignore while scrolling.
			return;
		}

		// Starting from the last section, find the first section with anything visible
		var firstVisibleSection = null;
		$($('.profile-section').get().reverse()).each(function () {
			if ($(this).offset().top + $(this).outerHeight() - $(window).scrollTop() > 78) {
				firstVisibleSection = $(this);
			}
		});
		if (!firstVisibleSection) {
			return;
		}
		// Update which nav item is selected
		highlightNavSection(firstVisibleSection.data('section'));
	}

	let highlightNavSection = (sectionName) => {
		$(
			'.js-profile-nav-container .active-link:not(.js-nav-link' + sectionName + ')',
		).removeClass('active-link');
		$('.js-nav-link-' + sectionName).addClass('active-link');
		$('#priority-nav .dropdown-menu a').removeClass('active-link');
		$('#priority-nav .dropdown-menu a[data-section=' + sectionName + ']').addClass(
			'active-link',
		);
		$('#priority-nav .nav-item.more')[
			$('#priority-nav .dropdown-menu a.active-link').length > 0
				? 'addClass'
				: 'removeClass'
		]('active-link');
		if (window.innerWidth <= 991) {
			// Auto scroll nav but ONLY on devices with mobile nav, otherwise bad things happen :)
			document.getElementById('parent-' + sectionName).scrollIntoView();
		}
	};

	const accreditations = () => {
		let template = Handlebars.compile($('#accreditation-item-template')[0].innerHTML);
		let spidh = $('#spidh-hv').val();

		Bark.api(`sellerprofile/${spidh}/accreditation`, null, ({ status, data }) => {
			data.forEach((item) => {
				$('.js-accreditation-container').append(template(item));
			});
		});
	};

	const showEditProfileLinks = () => {
		if ($('#hb-edit-profile-link').length) {
			const profileUrl = '/settings/profile/my_profile/?&ps=';
			const linkTemplate = Handlebars.compile(
				document.getElementById('hb-edit-profile-link').innerHTML,
			);
			$('[data-edit-profile-link]').each(function () {
				let $el = $(this);
				let addClasses = '';
				if ($el.data('editProfileLinkClass')) {
					addClasses = $el.data('editProfileLinkClass').replace(',', ' ');
				}
				let linkHtml = linkTemplate({
					section: profileUrl + $el.data('editProfileLink'),
					addClasses: addClasses,
				});
				$el.append(linkHtml);
			});
		}
	};

	const requestReplyRqSuccess = () => {
		let reqQuoteButton = $('.js-public-profile-main-info-sect').find('.js-request-quote');

		const requestReplyButton = $('.seller-public-profile-page').find('.js-request-quote');

		if (reqQuoteButton.length) {
			reqQuoteButton.prop('disabled', true);
			reqQuoteButton.text(
				_t('public_seller-profile:main-info-section.reply-requested-button'),
			);
		}

		if (requestReplyButton.length) {
			requestReplyButton.prop('disabled', true);
			requestReplyButton.text(
				_t('public_seller-profile:main-info-section.reply-requested-button'),
			);
		}
	};

	const getSellerAnalyticsMetadata = ($button) => {
		const sellerProfileId = $('#spidh-hv').val();
		const projectId = $('#pidh-hv').val();
		const buyerUserId = $button.data('buyerUserId');
		const list = ['2', '3'].includes(QS.getValue('slv', '-1')) ? 'marketplace' : 'all';
		const marketplaceCtas = [
			QS.getValue('marketplaceEnquiry', '0') === '1' ? 'enquiry' : false,
			QS.getValue('marketplaceMessage', '0') === '1' ? 'messages' : false,
			QS.getValue('bookNow', '0') === '1' ? 'booking' : false,
		].filter(Boolean);

		return {
			buyer_user_id: buyerUserId,
			project_id: projectId,
			seller_profile_id: sellerProfileId,
			list,
			marketplace_ctas: marketplaceCtas.join(', '),
			source: 'seller-profile',
		};
	};

	$(function () {
		// BarkVetted tooltip
		$('.bark-vetted-info').tooltip({
			title: _t('seller_profile:tooltip.bark-vetted-badge'),
			template:
				'<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-xs" style="max-width: 240px;"></div></div>',
			trigger: 'hover focus',
		});

		$('.js-profile-load-content')
			.off('click.getcontent')
			.on('click.getcontent', function (f) {
				var t = $(this);
				var data = t.data();
				var payload = {
					suidh: data.suidh,
					spidh: data.spidh,
					thing: data.thing,
				};
				var elem;
				var preventDefault = this.href.substring(this.href.length - 1) === '#';

				if (preventDefault) {
					f.preventDefault();
				}

				if (pendingAjax[payload.thing]) {
					return;
				}

				pendingAjax[payload.thing] = true;

				$.ajax({
					url: Bark.sprintf(
						'%s:%s/tpi',
						window.Bark.ENV.api_hostname,
						window.Bark.ENV.api_port,
					),
					beforeSend: function (xhr) {
						xhr.setRequestHeader('Accept', 'application/vnd.bark.v2+json');
					},
					type: 'post',
					data: payload,
				})
					.done(function (e) {
						if (e.status) {
							if (e.data) {
								// Set the content and then remove the click event
								elem = $('.js-profile-view-link-' + payload.thing);
								elem.text(e.data.label).off('click.getcontent');
								elem.attr({ href: e.data.href });
								elem.addClass('dark-blue');
							}
							return;
						}

						pendingAjax[payload.thing] = false;
						Bark.alertModal(_t('public_seller-profile:generic-error-message'));
					})
					.fail(function () {
						pendingAjax[payload.thing] = false;
						Bark.alertModal(_t('public_seller-profile:generic-error-message'));
					});

				// Record a link to the phone number, and send an email
				if (data.thing === 'tel') {
					Bark.api(
						'seller/contact',
						{
							type: 'phone',
							seller_profile_id: $(this).data('spidh'),
						},
						function () {
							// Do nothing
						},
						function (e) {
							bugsnagClient.notify(e);
						},
						'POST',
						Bark.apiVersionHeader('v2'),
					);
				}

				if (preventDefault) {
					return false;
				}
			});

		// Buyer Dashboard View Seller Experiment: record a click of the call event
		$('.js-call-seller')
			.off('click.record')
			.on('click.record', function () {
				Bark.json({
					url: '/api/exp-act/',
					data: {
						xpid: $(this).data('experiment-session-id'),
						act: 'clicked',
						actv: 'call-seller-button',
						pid: $(this).data('project-id'),
						pg: window.location.pathname,
					},
				});
			});

		if (
			$('.js-public-profile-main-info-sect').find('.js-enquiry-call-seller').length > 0
		) {
			const params = new URL(document.location).searchParams;
			const isMarketplaceEnquiry = params.get('marketplaceEnquiry') === '1';

			const spidh = $('#spidh-hv').val();
			const pidh = $('#pidh-hv').val();

			const $mainInfoSection = $('.js-public-profile-main-info-sect');

			$mainInfoSection.on('click', '.js-enquiry-call-seller', function (e) {
				e.preventDefault();

				const trackingData = {
					...getSellerAnalyticsMetadata($(this)),
					from_contact_now: false,
				};

				handleEnquiry(
					$('.js-enquiry-call-seller'),
					spidh,
					pidh,
					isMarketplaceEnquiry,
					trackingData,
					() => {
						$('.js-contact-button-row .js-request-quote').addClass('btn-primary');
					},
				);
			});
		}

		if (
			$('.js-public-profile-main-info-sect').find('.js-contact-options-seller').length > 0
		) {
			const params = new URL(document.location).searchParams;
			const isMarketplaceEnquiry = params.get('marketplaceEnquiry') === '1';
			const isMarketplaceMessage = params.get('marketplaceMessage') === '1';

			const sellerProfileId = $('#spidh-hv').val();
			const projectId = $('#pidh-hv').val();

			const $button = $('.js-contact-options-seller');
			const companyName = $button.data('companyName');
			const buyerUserId = $button.data('buyerUserId');

			const $mainInfoSection = $('.js-public-profile-main-info-sect');

			$mainInfoSection.on('click', '.js-contact-options-seller', function (e) {
				e.preventDefault();

				const trackingData = {
					...getSellerAnalyticsMetadata($(this)),
					from_contact_now: true,
				};

				barkTrackingLogEvent(
					'Callbacks-&-contact-options',
					'contact now - click',
					trackingData,
				);

				try {
					if (window.BuyerCallbacks) {
						window.BuyerCallbacks.launchModule(
							'callbacks-contact-options-modal',
							'__rctSellerListContactOptions',
							{
								sellerProfileId,
								projectId,
								buyerUserId,
								companyName,
								contactOptions: {
									message: isMarketplaceMessage,
									callNow: isMarketplaceEnquiry,
								},
								trackingData,
								onClickCall: () =>
									handleEnquiry(
										$button,
										sellerProfileId,
										projectId,
										isMarketplaceEnquiry,
										trackingData,
										() => {
											$('.js-contact-button-row .js-request-quote').addClass(
												'btn-primary',
											);
										},
									),
							},
						);
					}
				} catch (error) {
					console.log(error);
				}
			});
		}

		if ($('.js-send-message').length > 0) {
			const $button = $('.js-send-message');
			const params = new URL(document.location).searchParams;
			const isMarketplaceEnquiry = params.get('marketplaceEnquiry') === '1';
			const isMarketplaceMessage = params.get('marketplaceMessage') === '1';

			const sellerProfileId = $('#spidh-hv').val();
			const projectId = $('#pidh-hv').val();

			const companyName = $button.data('companyName');
			const buyerUserId = $button.data('buyerUserId');

			$button.on('click', function (e) {
				e.preventDefault();

				const trackingData = getSellerAnalyticsMetadata($(this));

				barkTrackingLogEvent('Callbacks-&-contact-options', 'message_button', {
					...trackingData,
					from_contact_now: false,
				});

				try {
					if (window.BuyerCallbacks) {
						window.BuyerCallbacks.launchModule(
							'callbacks-send-message-modal',
							'__rctSellerListSendMessage',
							{
								sellerProfileId,
								projectId,
								buyerUserId,
								companyName,
								trackingData,
							},
						);
					}
				} catch (error) {
					console.log(error);
				}
			});
		}

		// callbacks
		if ($('.js-public-profile-main-info-sect').find('.js-callback-seller').length > 0) {
			const $mainInfoSection = $('.js-public-profile-main-info-sect');

			$mainInfoSection.on('click', '.js-callback-seller', function (e) {
				e.preventDefault();
				const spidh = $('#spidh-hv').val();
				const pidh = $('#pidh-hv').val();

				const spid = $(this).data('spid');
				const companyName = $(this).data('companyName');
				const categoryId = $(this).data('categoryid');
				const buyerId = $(this).data('buyerUserId');

				barkTrackingLogEvent(
					'seller-&-profile',
					'Callbacks - Book a call - Select',
					getSellerAnalyticsMetadata($(this)),
				);

				handleBookNow(
					spid,
					companyName,
					buyerId,
					pidh,
					spidh,
					'seller-profile',
					categoryId,
				);
			});
		}

		if ($('.seller-public-profile-page').find('.js-request-quote').length > 0) {
			const spidh = $('#spidh-hv').val();
			const pidh = $('#pidh-hv').val();
			const requestQuoteModule = initRequestQuoteFromSellerJs({
				experimentTracking,
				source: 'seller-profile',
			});
			const $sellerProfileSection = $('.seller-public-profile-page');
			// Request quote from one
			const bpvid = $sellerProfileSection.find('.js-request-quote')?.data('bpv');
			const bpvsid = $sellerProfileSection.find('.js-request-quote')?.data('bpvs');
			// we may need to get BPV passed in too, see comment in seller-list.js
			// would also need changes to SP controller to passit along. Ugh.

			postResponseAction({
				type: 'profile-view',
				bpvsid,
				sellerProfileId: spidh,
				projectId: pidh,
				source: 'buyer-interaction',
			});

			$sellerProfileSection.on('click', '.js-request-quote', function () {
				let $self = $(this);

				if (window.BuyerApp) {
					window.BuyerApp.launchModule('request-reply-modal', '__request-reply-modal', {
						hashedProjectId: pidh,
						bpvsId: bpvsid,
						sellerId: spidh,
						buyerPhone: $self.data('buyerPhone'),
						onSuccess: requestReplyRqSuccess,
						// onClose,
						//onError,
					});
				}

				let data = {
					sellerProfileIds: spidh,
					projectId: pidh,
					bpvsId: bpvsid,
				};
			});

			$sellerProfileSection.on('actionSuccess', (event, data) => {
				let reqQuoteButton = $('.js-public-profile-main-info-sect').find(
					'.js-request-quote',
				);

				if (reqQuoteButton.length) {
					reqQuoteButton.prop('disabled', true);
					reqQuoteButton.text(
						_t('public_seller-profile:main-info-section.quote-was-requested-button'),
					);

					let sendMessageData = {
						sellers: [spidh],
						bpvid: bpvid,
						pridh: pidh,
						sellerProfileId: spidh,
					};
					// Using openModalSendToAll rather than openModal because it doesn't need the avatar partial to be compiled
					// The avatar partial relies on data specific to the seller list, so isn't appropriate to be re-used
					// in this context.
					requestQuoteModule.openModalSendToAll(sendMessageData);
				}
			});
		}

		if ($('.js-public-profile-main-info-sect').find('.js-request-quote').length > 0) {
			const spidh = $('#spidh-hv').val();
			const pidh = $('#pidh-hv').val();
			const requestQuoteModule = initRequestQuoteFromSellerJs({
				experimentTracking,
				source: 'seller-profile',
			});
			const $mainInfoSection = $('.js-public-profile-main-info-sect');
			// Request quote from one
			const bpvid = $mainInfoSection.find('.js-request-quote')?.data('bpv');
			const bpvsid = $mainInfoSection.find('.js-request-quote')?.data('bpvs');
			// we may need to get BPV passed in too, see comment in seller-list.js
			// would also need changes to SP controller to passit along. Ugh.

			postResponseAction({
				type: 'profile-view',
				bpvsid,
				sellerProfileId: spidh,
				projectId: pidh,
				source: 'buyer-interaction',
			});

			$mainInfoSection.on('click', '.js-request-quote', function () {
				let $self = $(this);

				if (window.BuyerApp) {
					window.BuyerApp.launchModule('request-reply-modal', '__request-reply-modal', {
						hashedProjectId: pidh,
						bpvsId: bpvsid,
						sellerId: spidh,
						buyerPhone: $self.data('buyerPhone'),
						onSuccess: requestReplyRqSuccess,
						// onClose,
						//onError,
					});
				}

				let data = {
					sellerProfileIds: spidh,
					projectId: pidh,
					bpvsId: bpvsid,
				};
			});

			$mainInfoSection.on('actionSuccess', (event, data) => {
				let reqQuoteButton = $('.js-public-profile-main-info-sect').find(
					'.js-request-quote',
				);

				if (reqQuoteButton.length) {
					reqQuoteButton.prop('disabled', true);
					reqQuoteButton.text(
						_t('public_seller-profile:main-info-section.quote-was-requested-button'),
					);

					let sendMessageData = {
						sellers: [spidh],
						bpvid: bpvid,
						pridh: pidh,
						sellerProfileId: spidh,
					};
					// Using openModalSendToAll rather than openModal because it doesn't need the avatar partial to be compiled
					// The avatar partial relies on data specific to the seller list, so isn't appropriate to be re-used
					// in this context.
					requestQuoteModule.openModalSendToAll(sendMessageData);
				}
			});
		}

		$('.js-media-see-all')
			.off('click.opengal')
			.on('click.opengal', function () {
				var t = $(this);
				var scope = t.closest('.js-section-container');

				$('.light-gallery a[href]:first', scope).click();
			});

		// Record a click on bark connect call
		$('.js-call-with-bark-connect')
			.off('click.record')
			.on('click.record', function () {
				Bark.json({
					url: '/api/exp-act/',
					data: {
						xpid: $(this).data('experiment-session-id'),
						act: 'clicked',
						actv: 'call-seller-button',
						pid: $(this).data('project-id'),
						pg: window.location.pathname,
					},
				});
			});

		$('.js-show-more-company-desc')
			.off('click.showmore')
			.on('click.showmore', function () {
				$('.js-company-desc-more').fadeIn(200);
				$('.js-section-about .no-line-break').removeClass('no-line-break');
				//Remove edit profile link from first paragraph
				$('#edit-profile-link-desc').remove();
				$(this).remove();
			});

		const $lg = $('.light-gallery');
		if ($lg.length) {
			$lg.lightGallery({ getCaptionFromTitleOrAlt: false });
			$lg.on('onBeforeOpen.lg', function () {
				$lg.find('img:not([src])').each(function (i, el) {
					$(el).attr('src', $(el).data('src'));
					$(el).attr('srcset', $(el).data('srcset'));
				});
			});
		}

		$('.js-profile-navigation-link a')
			.off('click.navigate')
			.on('click.navigate', function (e) {
				var page = $(this).data('section');
				e.preventDefault();
				$(window).off('scroll resize');
				scrollToSection(page);
				$('.dropdown-menu').removeClass('show');
				if ($(this).parents('.dropdown-menu').length) {
					$(this).addClass('active-link');
					$('.dropdown').addClass('active-link');
				} else {
					$('.dropdown').removeClass('active-link');
				}
				// Yep, I went there. Will refactor to await the completion in setSelected
				setTimeout(() => {
					$(window).on('scroll resize', setSelectedNav);
				}, 1000);
				return false;
			});

		var alt = '';
		$('.seller-avatar img.rounded-circle').on('error', function () {
			$(this).addClass('d-none');
			$(this).parent().find('.no-image-avatar-lg').removeClass('d-none');
			$(this).parent().find('.no-image-avatar-lg').addClass('d-inline-flex');
			if (alt !== $(this).prop('alt')) {
				alt = $(this).prop('alt');
				bugsnagClient.notify(new Error('seller-profile-image:failed'), {
					metaData: { 'alt-text': alt, location: 'public profile' },
					severity: 'info',
				});
			}
		});

		$('.seller-avatar img.rounded-circle').each(function () {
			if (
				$(this).hasClass('error') ||
				($(this).get()[0].complete &&
					$(this).get()[0].naturalWidth === 0 &&
					$(this).get()[0].naturalHeight === 0)
			) {
				$(this).trigger('error', 'dnr');
			}
		});

		if (Bark.GET('ampvtel')) {
			$('.js-profile-view-link-tel').click();
		}

		if (Bark.GET('ampvweb')) {
			$('.js-profile-view-link-website').click();
		}

		pageSection = Bark.GET('s');

		if (pageSection) {
			scrollToSection(pageSection);
		}

		openReviewsModal = Bark.GET('show_reviews');
		reviewsStars = Bark.GET('star');

		if (openReviewsModal && reviewsStars) {
			$('#add-review-modal ._st' + reviewsStars).click();
			$('#add-review-modal').modal('show');
		} else if (openReviewsModal) {
			$('#add-review-modal').modal('show');
		}

		if (Bark.GET('amp_clktel')) {
			$('.js-profile-view-link-tel').click();
		}

		if (Bark.GET('show-reviews')) {
			$('.js-nav-link-reviews  a').click();
		}

		var def = $.Deferred();
		window.priorityNavManager.init(null, def);

		if ($('#twitter-container').length) {
			// Load the Twitter library here, prior to calling the `.read()` function, to ensure the library is available
			window.twttr = (function (d, s, id) {
				var js,
					fjs = d.getElementsByTagName(s)[0],
					t = window.twttr || {};
				if (d.getElementById(id)) return;
				js = d.createElement(s);
				js.id = id;
				js.src = 'https://platform.twitter.com/widgets.js';
				fjs.parentNode.insertBefore(js, fjs);

				t._e = [];
				t.ready = function (f) {
					t._e.push(f);
				};

				return t;
			})(document, 'script', 'twitter-wjs');

			window.twttr.ready(function (twttr) {
				twttr.widgets
					.createTimeline(
						{
							sourceType: 'profile',
							screenName: $('#twitter-container').data('handle'),
						},
						$('#twitter-container')[0],
						{
							chrome: 'noheader nofooter noborders',
							tweetLimit: 5,
						},
					)
					.then(function (el) {
						if (!el) {
							// This element is returned if the feed is successfully created. If the element was not
							// returned, the feed failed for some reason. The Twitter account may no longer be active.

							// Remove the Twitter sections
							$('.section-twitter').remove();

							// Remove the Twitter nav item
							$('#parent-twitter,a[data-section=twitter]').remove();

							// Trigger a window resize, in order to re-calculate how many tabs fit on the sub-navigation
							$(window).trigger('resize');
						} else {
							// Show the Twitter section
							$('.section-twitter').removeClass('d-none');

							// Show the Twitter nav item
							$('#parent-twitter,a[data-section=twitter]').removeClass('d-none');

							// Trigger a window resize, in order to re-calculate how many tabs fit on the sub-navigation
							$(window).trigger('resize');
						}
					});
			});
		}
		window.QS.deleteValue('_loc');

		function fixLayoutInProjectContext() {
			// When logged in as a buyer in project context, there is another nav bar :"Back to quotes".
			// This function deals with the extra height of that nav.

			let backToQuotesSelector = '.seller-profile-back-to-quotes-container';
			let isDesktopLayout = $(window).width() >= 1024;

			// Desktop fixes. Mobile replaces the 'Bark' nav wih the 'back to quotes' one
			if ($(backToQuotesSelector).length && isDesktopLayout) {
				$('.section-navigation').css({ top: '125px' });
				$('.seller-profile-section-container').css({ marginBottom: '125px' });
			}

			// Mobile fixes
			if ($(backToQuotesSelector).length && !isDesktopLayout) {
				// Adjust padding above profile image
				// Using attr because .css doesn't understand !important
				$('.seller-public-profile-main-info-sect').attr(
					'style',
					'padding-top: 10px!important',
				);
			}
		}

		function changeStateOfCarouselScrollButtonsBasedOnScrollPosition() {
			let carouselTypes = ['video', 'image'];
			carouselTypes.forEach(function (carouselType) {
				let carouselSelector = $('#' + carouselType + '-container');
				let forwardButtonSelector = $('.js-' + carouselType + '-scroll-forwards');
				let backButtonSelector = $('.js-' + carouselType + '-scroll-back');

				// Back scroll button state
				if (carouselSelector.prop('scrollLeft') > 0) {
					// Enable the 'scroll right' button
					backButtonSelector.removeClass('disabled');
				} else {
					backButtonSelector.addClass('disabled');
				}

				// Forward scroll button state
				if (
					carouselSelector.prop('scrollLeft') + carouselSelector.prop('clientWidth') <
					carouselSelector.prop('scrollWidth')
				) {
					forwardButtonSelector.removeClass('disabled');
				} else {
					// User has scrolled all the way forwards, to the end of the container, so disable scroll forward button
					forwardButtonSelector.addClass('disabled');
				}
			});
		}

		function tenderBrowseExperimentHandler() {
			let browseCookie = window.browseFunctions.getBlid();
			browseFunctions.tracking('Browse-list - profile view', {
				sellerProfileId: $('#spidh-hv').val(),
			});
			return browseCookie && $('.js-request-quote-tenderbrowse-invoke').length > 0;
		}

		$('#image-container').on(
			'scroll resize',
			changeStateOfCarouselScrollButtonsBasedOnScrollPosition,
		);
		$('#video-container').on(
			'scroll resize',
			changeStateOfCarouselScrollButtonsBasedOnScrollPosition,
		);

		const list = QS.getValue('slv', '-1') === '1' ? 'marketplace' : 'all';
		if (list === 'marketplace') {
			console.log('before Hj trigger: is on seller expanded or enquiry profile page');
			window?.Bark?.triggerHotjarRecording('marketplace_seller_separation_list');
		}

		$(window).on('scroll resize', setSelectedNav);

		loadInPrices();
		loadInCompanyInfo();
		loadInRemoteServiceAvailability();
		fixLayoutInProjectContext();
		setSelectedNav();
		if (!tenderBrowseExperimentHandler()) {
			experimentTracking.initialiseForProfile(
				'seller-profile',
				getSellerAnalyticsMetadata,
			);
		}

		if ($('.js-overview-section:visible').length > 0) {
			$('.js-overview-header').show();
		}
		if ($('#accreditation-item-template').length) {
			$('.js-section-accreditation').show();
			accreditations();
		}
		changeStateOfCarouselScrollButtonsBasedOnScrollPosition();

		showEditProfileLinks();

		if (Bark.GET('show-reviews')) {
			// not ideal, but till we refactor this and use promises.
			setTimeout(() => {
				$('.js-nav-link-reviews  a').click();
			}, 1000);
		}

		if (!isPublicProfile()) {
			let urlPath = location.href.split('/');
			let sellerId = urlPath[urlPath.length - 2];
			getBuyerProjectSeller(
				QS.getValue('prid'),
				sellerId,
				(result) => {
					let locationDistance = result.data?.attributes?.location_distance;
					if (locationDistance && locationDistance.trim().length > 0) {
						$('.seller-location.seller-location-distance .val').text(locationDistance);
						$('.seller-location.seller-location-distance').removeClass('d-none');
					}
				},
				(error) => {
					bugsnagClient.notify(new Error('Failed to get project seller data'), {
						metaData: error,
					});
				},
			);
		}
	});
}
