import { tracking } from './bark-tracking';

class TrackingEvent {
	constructor({ id, name, group, entities }) {
		this.id = id;
		this.name = name;
		this.group = group;
		this.entities = entities;
	}
}

class TrackingEntity {
	constructor({ id, data = {} }) {
		this.id = id;
		this.data = data;
	}

	addProperty({ name, value }) {
		this.data[name] = value;
	}

	addProperties(properties) {
		for (const name in properties) {
			this.addProperty({ name, value: properties[name] });
		}
	}

	getData() {
		return this.data;
	}

	getPrefixedData() {
		let prefixedData = {};
		for (const key in this.data) {
			prefixedData[`${this.id}.${key}`] = this.data[key];
		}
		return prefixedData;
	}
}

export class EntityTracking {
	constructor({ nameFormat = 'title', debug = false }) {
		this._entities = {};
		this._events = {};
		this._debug = debug;
		this.nameFormat = nameFormat;
	}

	createEvent({ id, name, group, entities }) {
		this._events[id] = new TrackingEvent({ id, name, group, entities });
	}

	createEntity({ id, data = {} }) {
		this._entities[id] = new TrackingEntity({ id, data });
	}

	createEntities(entities) {
		for (const entity of entities) {
			this.createEntity(entity);
		}
	}

	createEvents(events) {
		for (const event of events) {
			this.createEvent(event);
		}
	}

	replaceEntity(id, data) {
		if (this._entities[id]) {
			delete this._entities[id];
		}
		this.updateEntity(id, data);
	}

	updateEntity(id, data) {
		if (!this._entities[id]) {
			this.createEntity({ id, data });
			return;
		}
		this._entities[id].addProperties(data);
	}

	buildPayload(entities) {
		let payload = {};
		for (const entityId of entities) {
			if (this._entities[entityId]) {
				Object.assign(payload, this._entities[entityId].getPrefixedData());
			} else {
				bugsnagClient.notify(new Error('Entity Tracking - Entity not found'), {
					metaData: { entityId },
					severity: 'info',
				});
			}
		}
		return payload;
	}

	isDebug() {
		return this._debug;
	}

	format(value, format) {
		if (typeof format === 'undefined' || format == null || !format) {
			return value;
		} else if (format === 'title') {
			return value.replace(/\w\S*/g, function (txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1);
			});
		}
		return value;
	}

	fireTracking(eventId, additionalData) {
		if (!this._events[eventId]) {
			bugsnagClient.notify(new Error('Entity Tracking - Event not found'), {
				metaData: { eventId },
				severity: 'warning',
			});
			return;
		}
		const { group: eventGroup, name: eventName, entities } = this._events[eventId];
		let payload = this.buildPayload(entities);
		payload = {
			...payload,
			...additionalData,
		};
		const groupFormatted = this.format(eventGroup, this.nameFormat);
		const nameFormatted = this.format(eventName, this.nameFormat);
		if (this.isDebug()) {
			console.log(
				'Tracking event fired:',
				`${groupFormatted} - ${nameFormatted}`,
				payload,
			);
		}
		tracking(groupFormatted, nameFormatted, payload);
	}
}
