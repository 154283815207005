import {initCategoryAutocompleteForInput} from "../autocomplete-inputs/category-autocomplete";
import {setupDynamicPostCodeAutocomplete} from "../autocomplete-inputs/postcode-autocomplete";
import {default as StreamComplete} from "../legacy-scripts/streamcomplete";
import {initCore} from "./index";
import {initBarkEntryService} from "../new-request-flow/components/entry-service/start-bark";
import { default as EmailValidator } from "../legacy-scripts/email_validator";
import { default as BasicPhoneCheck } from "../legacy-scripts/basic-phone-check";
import { getPayloadForStartBarkFromHeader } from '../new-request-flow/components/entry-service/create-bark-payload';
import { setInitialReferrerCookie, setBarkLandingPageCookie } from '../tracking';
import '../onetrust/onetrust';

export const initBarkCoreLight = async () => {
	await initCore();
	initBarkEntryService();
	StreamComplete(0, window, window.document);
	setUpAutoCompletes();

	// Drop a couple of cookies to help us track organic barks
	setInitialReferrerCookie();
	setBarkLandingPageCookie();

	// extra functions that expose globals to the window used throughout the legacy code base.
	EmailValidator();
	BasicPhoneCheck();
};

const setUpAutoCompletes = () => {
    if (document.getElementById('category_id')) {
        initCategoryAutocompleteForInput('category_name', 'category_id');
    }

    if (document.getElementById('category_name_top')) {
        initCategoryAutocompleteForInput('category_name_top', 'category_id_top',{
            isStartBark: true,
            payload: getPayloadForStartBarkFromHeader,
        });
    }

    if (document.getElementById('postcode')) {
        setupDynamicPostCodeAutocomplete('postcode');
    }
}
