function hasPerformanceCookiesEnabled(activeCookieGroups) {
	return activeCookieGroups.match('C0002');
}

function hasTrackingCookiesEnabled(activeCookieGroups) {
	return activeCookieGroups.match('C0004');
}

function reportToHeap(activeCookieGroups) {
	if (!window.heap) return;

	let hasPerformance = activeCookieGroups.includes('C0002');
	let hasTracking = activeCookieGroups.includes('C0004');
	let cookieMsg = 'Accepted - All Cookies';

	if (hasPerformance && !hasTracking) {
		cookieMsg = 'Accepted - Performance Cookies Only';
	} else if (!hasPerformance && hasTracking) {
		cookieMsg = 'Accepted - Tracking Cookies Only';
	} else if (!hasPerformance && !hasTracking) {
		cookieMsg = 'Rejected All Cookies';
	}

	window.heap.track(`User - ${cookieMsg}`);
}

function hasClosedCookieBanner() {
	return document.cookie
		.split(';')
		.map((cookie) => cookie.trim().split('=')[0])
		.includes('OptanonAlertBoxClosed');
}

function isCookieBannerVisible() {
	return !!document.getElementById('onetrust-pc-btn-handler'); // Making sure there is a cookie banner shown
}

function deleteCookiesByActiveGroup(activeGroups) {
	// Delete cookies after a few checks

	const filteredActiveGroups = activeGroups.split(',').filter(Boolean);

	OneTrust.GetDomainData()
		.Groups.filter((group) => !filteredActiveGroups.includes(group.CustomGroupId))
		.forEach((grp) => {
			grp.Cookies.forEach((cookie) => {
				deleteCookie(cookie);
			});

			grp.Hosts.forEach((host) =>
				host.Cookies.forEach((cookie) => {
					deleteCookie(cookie);
				}),
			);
		});
}

function deleteCookie({ Name: name, Host: domain }) {
	if (!name || !domain) return;

	document.cookie = name + '=; value=NOTHING; Max-Age=-99999999; Path=/;Domain=' + domain;
	document.cookie = name + '=; Max-Age=-99999999; Path=/;';

	//Delete LSO incase LSO being used, cna be commented out.
	localStorage.removeItem(name);
}

function updateTagManager(activeGroups) {
	if (hasPerformanceCookiesEnabled(activeGroups)) {
		gtag('consent', 'update', { analytics_storage: 'granted' });
	}

	if (hasTrackingCookiesEnabled(activeGroups)) {
		gtag('consent', 'update', { ad_storage: 'granted' });
	}
}

let oneTrustWrapperExecutionCount = 0;

// This function is called every single time something happens in the OneTrust JS SDK, and needs to be available globally
window.OptanonWrapper = function () {
	deleteCookiesByActiveGroup(window.OnetrustActiveGroups);

	OneTrust.OnConsentChanged(function (e) {
		if (e.type === 'consent.onetrust') {
			reportToHeap(e.detail);
			updateTagManager(window.OnetrustActiveGroups);
		}
	});

	if (!hasClosedCookieBanner() && isCookieBannerVisible()) {
		window.heap?.track('User - Seen cookie banner');

		if (oneTrustWrapperExecutionCount === 0) {
			document.getElementById('onetrust-pc-btn-handler').addEventListener('click', () => {
				window.heap?.track('User - Clicked cookie settings button');
			});
		}
	}

	oneTrustWrapperExecutionCount++;
};
